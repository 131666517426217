import {EventActionType} from "@/constant/tracking/TrackingConstant";
import {apiSendEventAction} from "@/api/tracking/ApiTracking";
import {getTimeStampNow} from "@/helper/TimeHelper";
import {getBrowserInfo} from "@/helper/BrowserHelper";
import {getLocalStorageExtension} from "@/service/extension/ExtensionExternalService";
import {
  LocalStorageExtensionConstant,
  BEECOST_EXTENSION_ID
} from "@/constant/extension/ExtensionConstant";
import {readCookie} from "@/helper/CookieHelper";

const trackingEnterReferralCodeAction = async (optionTracking) => {
  let trackingCommonInfo = await getTrackingCommonInfo()
  await apiSendEventAction(
    EventActionType.enterReferralCode,
    trackingCommonInfo,
    optionTracking
  )
}

const getTrackingCommonInfo = async () => {
  let clientId = readCookie('client_extension_id')
  let pub = await getLocalStorageExtension(BEECOST_EXTENSION_ID, LocalStorageExtensionConstant.PUB)
  let browserId = clientId
  let browserVersion = `${getBrowserInfo().name} - ${getBrowserInfo().fullVersion}`
  let browserUa = navigator?.userAgent
  let os = navigator?.platform
  // let ip = ''
  let deviceName = ''
  let deviceMacAddress = ''
  let time = getTimeStampNow()
  return {
    browserId,
    browserVersion,
    browserUa,
    os,
    // ip,
    deviceName,
    deviceMacAddress,
    time,
    pub
  }
}

const hashOptionSearch = (options) =>  {
  if (!options) {
    return ''
  }
  let sortBy = options.sort_by
  let numberExcludeQueries = options.exclude_query?.queries | '0'
  let numberExcludeBrands = options.exclude_query?.brands | '0'
  let platform = options.include_query?.platforms
  let numberIncludeQueries = options.include_query?.queries | '0'
  let numberIncludeBrands = options.include_query?.brands | '0'
  let numberIncludeRange = typeof options.include_query === 'object'
    ? Object.keys(options.include_query)
      .filter(key => key.indexOf('d_range') > 0)
      .map(key => !!options.include_query[key]?.from > 1 || !!options.include_query[key]?.to > 1)
      .filter(value => value)
      .length
    : ''
  let numberIncludeLocations = options.include_query?.locations | 'all'
  let numberIncludeCategories = options.include_query?.categories | 'all'
  let numberIncludeComparisons = options.include_query?.composite_compare_lst?.length | '0'
  return `P${platform}_${sortBy}_${numberIncludeQueries}Queries_${numberIncludeBrands}Brand_${numberIncludeRange}Range_${numberIncludeLocations}Location_${numberIncludeCategories}Category_${numberIncludeComparisons}Compare_|_Exclude_${numberExcludeQueries}Query_${numberExcludeBrands}Brand`
}

export {
  trackingEnterReferralCodeAction,
  hashOptionSearch
}
