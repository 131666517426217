import {http} from "@/plugin/http";
import {urlApiSendEventAction} from "@/api/tracking/TrackingEndpoint";

const apiSendEventAction = async (type, trackingCommonInfo, option) => {
  let urlApi = urlApiSendEventAction()
  try {
    const data = {
      "browser_id": trackingCommonInfo.browserId,
      "browser_version": trackingCommonInfo.browserVersion,
      "browser_ua": trackingCommonInfo.browserUa,
      "os": trackingCommonInfo.os,
      "type": type,
      "device_name": trackingCommonInfo.deviceName,
      "device_mac_address": trackingCommonInfo.deviceMacAddress,
      "created_at": trackingCommonInfo.time,
      "pub": trackingCommonInfo.pub,
      "option": option
    }
    const response = await http.post(urlApi, data)
    if (response) {
      return response
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

export {
  apiSendEventAction
}
