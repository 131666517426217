const EventActionType = {
  install: "install",
  uninstall: "uninstall",
  update: "update",
  openBrowser: "open_browser",
  enterReferralCode: 'enter_ref_code'
}

export {
  EventActionType
}
