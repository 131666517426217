<template>
  <MobileDefault v-if="isMobile" class="mobile-view">
    <div class="container-bg-white container-space-mobile-default">
      <h1 class="title"><span class="border-left-line"></span>Thông tin tài khoản</h1>
      <div class="info columns" v-if="profile">
        <div class="table-info">
          <figure class="image">
            <img class="img-responsive img-avatar is-rounded" :src="profile.url_thumbnail"
                 :alt="profile.full_name">
          </figure>
          <div class="value full-name">
            {{ profile.full_name }}
          </div>
          <div class="value email line-clamp__1">
            {{ profile.email }}
          </div>
        </div>
        <div class="info-detail">
          <div class="attribute">
            <div class="field">
              Gói sử dụng:
            </div>
            <div>
              <div class="" style="">
                <div class="value" style="">
                  <span :class="isExpired ? '' : 'text-primary-500'">{{accountPlanName}}</span>
                  <div v-if="isExpired" class="value" style="">
                    <span v-if="!isDefaultAccountPlan" style="color: #ee0033">Đã hết hạn</span>
                  </div>
                </div>
                <div v-if="!isExpired" class="flex items-center"><span class="text-action" @click="onClickShowPricingPlanV2">Danh sách gói</span></div>
              </div>
            </div>
          </div>
          <div class="attribute" v-if="typeof freeSearchCount === 'number' && freeSearchCount <= 100 && !isCompanyAccount && !isStandardAccount">
            <div class="field">
              Lượt phân tích {{isDefaultAccount ? 'miễn phí' : ''}} còn lại trong ngày:
            </div>
            <div class="is-primary is-light value margin-left-5 flex items-center justify-center" style="">
              <span class="margin-left-5">
                <span class="" :style="freeSearchCount === 0 ? `color: #ee0033` : ''">{{ freeSearchCount }} lượt phân tích</span>
                <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
              </span>
            </div>
          </div>
          <div class="attribute" v-if="typeof freeSearchCountPerWeek === 'number' && !isCompanyAccount">
            <div class="field">
              Lượt phân tích còn lại trong tuần:
            </div>
            <div class="is-primary is-light value margin-left-5 flex items-center justify-center" style="">
              <span class="margin-left-5">
                <span class="" :style="freeSearchCountPerWeek === 0 ? `color: #ee0033` : ''">{{ freeSearchCountPerWeek }} lượt phân tích</span>
                <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
              </span>
            </div>
          </div>
          <div class="attribute"
               v-if="typeof remainProductDownloadCountPerWeek === 'number'
                  && PERMISSION[this.permissionAvailableName]
                  && PERMISSION[this.permissionAvailableName].allLimitLineProductDownload
                  && remainProductDownloadCountPerWeek < PERMISSION[this.permissionAvailableName].allLimitLineProductDownload[PERMISSION[this.permissionAvailableName].allLimitLineProductDownload.length - 1]"
          >
            <div class="field">
              Số dòng data được download trong tuần:
            </div>
            <div class="is-primary is-light value margin-left-5 flex items-center justify-center" style="">
              <span class="margin-left-5">
                <span class="" :style="remainProductDownloadCountPerWeek === 0 ? `color: #ee0033` : ''">{{ remainProductDownloadCountPerWeek }} dòng data</span>
                <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
              </span>
            </div>
          </div>
          <div class="attribute" v-if="inactiveVouchers">
            <div class="field">
              Gói chờ kích hoạt:
            </div>
            <div class="value tag is-primary is-light" style="">
              {{ inactiveVouchers.voucher_code }}
            </div>
            <div class="value tooltip" style="display: flex; align-items: center; margin-left: 10px;">
              <div @click="onClickCopy(inactiveVouchers.voucher_code, 'profile_view_tooltip_mobile')">
                <span class="tooltiptext" id="profile_view_tooltip_mobile">Đã sao chép</span>
                <span>Copy Mã gửi Người mới</span>
              </div>
            </div>
          </div>
          <div class="attribute" v-if="inactiveVouchers">
            <div class="field">
              Điều kiện:
            </div>
            <div class="value">
              <p>Cần thêm <span class="remain-user-to-active">{{ inactiveVouchers.remain_user_to_active }}</span> Người
                mới áp dụng mã để kích hoạt voucher</p>
            </div>
          </div>
          <div class="attribute" v-if="!isExpired">
            <div class="field">
              Hạn dùng:
            </div>
            <div class="value">
              <template v-if="isExpired">
                <p>Đã hết hạn (<a @click="onClickUpgrade">Gia hạn tài khoản</a>)</p>
              </template>
              <template v-if="!isExpired">
                <p>{{ expirationDate.toLocaleDateString('vi-VI') }}</p>
              </template>

            </div>
          </div>
          <div class="attribute" v-if="!isHigherPriorityAccount(PERMISSION.market_basic.priority)">
            <div class="field">
              <span v-if="isExpired">Việc cần làm:</span>
              <span v-else>Gợi ý:</span>
            </div>
            <div style="">
              <p class="">
                <span @click="onClickUpgrade" class="text-highlight text-action">Nâng cấp Tài khoản</span>
<!--                <span> hoặc nâng cấp thông qua <a target="_blank" class="text-action" @click="onClickBtnPromote">gói giới thiệu</a></span>-->
              </p>
            </div>
          </div>
<!--          <div class="attribute" style="" v-if="marketReferralLink">-->
<!--            <div class="field">-->
<!--              Link giới thiệu:-->
<!--            </div>-->
<!--            <div class="flex flex-row justify-space-between w-full">-->
<!--              <span class="text-link" style="word-break: break-word">{{ marketReferralLink }}</span>-->
<!--              <div class="tooltip ml-0.5 mr-4" @click="onClickCopy(marketReferralLink, 'referral_link_tooltip_mobile')">-->
<!--                <div class="cursor-pointer" type="default" size="small">-->
<!--                  <span class="tooltiptext" id="referral_link_tooltip_mobile">Đã copy</span>-->
<!--                  <a-icon type="copy"  class="text-primary-500"/>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="attribute" v-if="profile.referral_email">-->
<!--            <div class="field" style="color: #b1b0b0">-->
<!--              Người giới thiệu:-->
<!--            </div>-->
<!--            <div class="">-->
<!--              <template>-->
<!--                <p style="color: #b1b0b0">{{ profile.referral_email }}</p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="container-bg-white container-space-mobile-default">
      <h1 class="title"><span class="border-left-line"></span>Thông tin thanh toán
        <a-spin style="margin-left: 8px" v-if="isLoadingPayment"></a-spin>
      </h1>
      <table class="payment-table" v-if="allPayment">
        <tr class="table-header-row">
          <th>Thời gian mua</th>
          <th>Tên gói sản phẩm</th>
          <th>Thời gian sử dụng</th>
          <th>Trạng thái</th>
          <th style="text-align: center">Thông tin</th>
        </tr>
        <tr class="table-content-row" v-for="(payment, index) in allPayment" :key="`${payment.voucher_code}_${index}`">
          <td>{{ payment.init_time | formatTime }}</td>
          <td><span>{{ payment.name }}</span><br><span>Mã: {{payment.voucher_code}}</span></td>
          <td class="text-center">{{ payment.content }}</td>
          <td :class="`status-${payment.state.toLowerCase()}`" class="text-center">{{ payment.state | formatState }}</td>
          <td class="text-action text-center"><span @click="onClickShowPaymentDetail(payment.voucher_id, payment.voucher_code)">Xem chi tiết</span>
          </td>
        </tr>
      </table>
      <p v-if="!allPayment && !isLoadingPayment">Hiện bạn chưa có thanh toán nào</p>
    </div>
  </MobileDefault>
  <Default v-else class="page-search">
    <div class="container-bg-white container-space-default">
      <h1 class="title"><span class="border-left-line"></span>Thông tin tài khoản</h1>
      <div class="info columns" v-if="profile">
        <div class="column table-info is-4">
          <figure class="image is-128x128">
            <img class="img-responsive img-avatar is-rounded" :src="profile.url_thumbnail"
                 :alt="profile.full_name">
          </figure>
          <div class="value full-name">
            {{ profile.full_name }}
          </div>
          <div class="value email">
            {{ profile.email }}
          </div>
        </div>
        <div class="column is-8 info-detail">
          <div class="attribute">
            <div class="field">
              Gói sử dụng:
            </div>
            <div>
              <div class="flex">
                <div class="value tag is-primary is-light" style="font-size: 15px">
                  <span>{{isExpired ? accountPlanName : accountName}}</span>
                </div>
                <div v-if="isExpired" class="is-primary is-light value margin-left-5 flex items-center justify-center" style="font-size: 15px; height: 2em;">
                  <span v-if="!isDefaultAccountPlan" style="color: #ee0033">- Đã hết hạn</span>
                </div>
                <div v-if="!isExpired" class="flex items-center ml-2 text-sm"><span class="text-action" @click="onClickShowPricingPlanV2">Danh sách gói</span></div>
              </div>
<!--              <p v-if="isExpired">-->
<!--                Bạn đang sử dụng theo gói-->
<!--                <span class="margin-left-5 value tag is-primary is-light" style="font-size: 15px">-->
<!--                  <span>{{accountName}}</span>-->
<!--                </span>-->
<!--              </p>-->
            </div>
          </div>
          <div class="attribute" v-if="typeof freeSearchCount === 'number' && freeSearchCount <= 100 && !isBusinessAccount && !isStandardAccount">
            <div class="field">
              Lượt phân tích {{isDefaultAccount ? 'miễn phí' : ''}} còn lại trong ngày:
            </div>
            <div class="is-primary is-light value margin-left-5 flex items-center justify-center" style="font-size: 15px; height: 2em;">
              <span class="margin-left-5">
                <span class="" :style="freeSearchCount === 0 ? `color: #ee0033` : ''">{{ freeSearchCount }} lượt phân tích</span>
                <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
              </span>
            </div>
          </div>
          <div class="attribute" v-if="typeof freeSearchCountPerWeek === 'number' && !isCompanyAccount">
            <div class="field">
              Lượt phân tích còn lại trong tuần:
            </div>
            <div class="is-primary is-light value margin-left-5 flex items-center justify-center" style="font-size: 15px; height: 2em;">
              <span class="margin-left-5">
                <span class="" :style="freeSearchCountPerWeek === 0 ? `color: #ee0033` : ''">{{ freeSearchCountPerWeek }} lượt phân tích</span>
                <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
              </span>
            </div>
          </div>

          <div class="attribute"
               v-if="typeof remainProductDownloadCountPerWeek === 'number'
                  && PERMISSION[this.permissionAvailableName]
                  && PERMISSION[this.permissionAvailableName].allLimitLineProductDownload
                  && remainProductDownloadCountPerWeek < PERMISSION[this.permissionAvailableName].allLimitLineProductDownload[PERMISSION[this.permissionAvailableName].allLimitLineProductDownload.length - 1]"
          >
            <div class="field">
              Số dòng data được download trong tuần:
            </div>
            <div class="is-primary is-light value margin-left-5 flex items-center justify-center" style="font-size: 15px; height: 2em;">
              <span class="margin-left-5">
                <span class="" :style="remainProductDownloadCountPerWeek === 0 ? `color: #ee0033` : ''">{{ remainProductDownloadCountPerWeek }} dòng data</span>
                <a-icon @click="onClickShowNoti" type="question-circle" style="margin-left: 8px; cursor:pointer;"/>
              </span>
            </div>
          </div>
          <div class="attribute" v-if="inactiveVouchers">
            <div class="field">
              Gói chờ kích hoạt:
            </div>
            <div class="value tag is-primary is-light" style="font-size: 15px">
              {{ inactiveVouchers.voucher_code }}
            </div>
            <div class="value tooltip" style="display: flex; align-items: center; margin-left: 10px;">
              <a-button size="small" @click="onClickCopy(inactiveVouchers.voucher_code, 'profile_view_tooltip')">
                <span class="tooltiptext" id="profile_view_tooltip">Đã sao chép</span>
                <span>Copy Mã gửi Người mới</span>
              </a-button>
            </div>
          </div>
          <div class="attribute" v-if="inactiveVouchers">
            <div class="field">
              Điều kiện:
            </div>
            <div class="value">
              <p>Cần thêm <span class="remain-user-to-active">{{ inactiveVouchers.remain_user_to_active }}</span> Người
                mới áp dụng mã để kích hoạt voucher</p>
            </div>
          </div>
          <div class="attribute" v-if="!isExpired">
            <div class="field">
              Hạn dùng:
            </div>
            <div class="value">
              <template v-if="isExpired">
                <p>Đã hết hạn (<a @click="onClickUpgrade">Gia hạn tài khoản</a>)</p>
              </template>
              <template v-if="!isExpired">
                <p>{{ expirationDate.toLocaleDateString('vi-VI') }}</p>
              </template>

            </div>
          </div>
          <div class="attribute" v-if="!isHigherPriorityAccount(PERMISSION.market_basic.priority)">
            <div class="field">
              <span v-if="isExpired">Việc cần làm:</span>
              <span v-else>Gợi ý:</span>
            </div>
            <div style="font-size: 16px">
              <p class="">
                <span @click="onClickUpgrade" class="text-highlight text-action">Nâng cấp Tài khoản</span>
<!--                <span> hoặc nâng cấp thông qua <a target="_blank" class="text-action" @click="onClickBtnPromote">gói giới thiệu</a></span>-->
              </p>
              <!--              <p>(Không Giới Hạn lượt phân tích)</p>-->
            </div>
          </div>
<!--          <div class="attribute" style="margin-top: 16px" v-if="marketReferralLink">-->
<!--            <div class="field">-->
<!--              Link giới thiệu:-->
<!--            </div>-->
<!--            <div class="">-->
<!--              <span class="text-link">{{ marketReferralLink }}</span>-->
<!--              <div class="tooltip btn-action-wrap" @click="onClickCopy(marketReferralLink, 'referral_link_tooltip')">-->
<!--                <div class="cursor-pointer" type="default" size="small">-->
<!--                  <span class="tooltiptext" id="referral_link_tooltip">Đã copy</span>-->
<!--                  <span>Copy</span>-->
<!--                </div>-->
<!--              </div>-->
<!--&lt;!&ndash;              <p class="text-note">&ndash;&gt;-->
<!--&lt;!&ndash;                Dùng cho khuyến mại <span class="text-action"&ndash;&gt;-->
<!--&lt;!&ndash;                                          @click="onClickViewReferralProgram">giới thiệu người mới</span>&ndash;&gt;-->
<!--&lt;!&ndash;              </p>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="attribute" v-if="profile.referral_email">-->
<!--            <div class="field" style="color: #b1b0b0">-->
<!--              Người giới thiệu:-->
<!--            </div>-->
<!--            <div class="">-->
<!--              <template>-->
<!--                <p style="color: #b1b0b0">{{ profile.referral_email }}</p>-->
<!--              </template>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="container-bg-white container-space-default">
      <h1 class="title"><span class="border-left-line"></span>Thông tin thanh toán
        <a-spin style="margin-left: 8px" v-if="isLoadingPayment"></a-spin>
      </h1>
      <table class="payment-table" v-if="allPayment">
        <tr class="table-header-row">
          <th>Thời gian mua</th>
          <th>Tên gói sản phẩm</th>
          <th>Mã gói sản phẩm</th>
          <th>Thời gian sử dụng</th>
          <th>Trạng thái</th>
          <th style="text-align: center">Thông tin</th>
        </tr>
        <tr class="table-content-row" v-for="(payment, index) in allPayment" :key="`${payment.voucher_code}_${index}`">
          <td>{{ payment.init_time | formatTime }}</td>
          <td>{{ payment.name }}</td>
          <td>{{ payment.voucher_code }}</td>
          <td>{{ payment.content }}</td>
          <td :class="`status-${payment.state.toLowerCase()}`">{{ payment.state | formatState }}</td>
          <td class="text-action"><span @click="onClickShowPaymentDetail(payment.voucher_id, payment.voucher_code)">Xem chi tiết</span>
          </td>
        </tr>
      </table>
      <p v-if="!allPayment && !isLoadingPayment">Hiện bạn chưa có thanh toán nào</p>
    </div>
  </Default>
</template>

<script>
import Default from '@/layout/Default'
import {
  addVoucherWaiting,
  getUserProfile,
  resetListVoucherWaiting
} from '@/service/user/UserProfileService'
import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
import { ActionNameConstants, LIST_REFERRAL_CODE } from '@/constant/general/GeneralConstant'
import { TRANSACTION_STATE } from '@/constant/pricing/PricingPlansConstant'
import { MutationSidebar } from '@/store/modules/SidebarModule/mutation'
import { getLocalStorageExtension, setLocalStorageExtension } from '@/service/extension/ExtensionExternalService'
import { LocalStorageExtensionConstant, BEECOST_EXTENSION_ID } from '@/constant/extension/ExtensionConstant'
import { trackingEnterReferralCodeAction } from '@/service/tracking/TrackingCommonService'
import { copyToClipboard } from '@/helper/StringHelper'
import { getListTransaction } from '@/service/payment/PaymentService'
import {mixinStoreGetterUser, mixinStoreGetterPayment, mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import MobileDefault from "@/layout/MobileDefault";

export default {
  components: {
    Default,
    MobileDefault
  },
  data() {
    return {
      profile: null,
      referralPersonName: null,
      referralCode: null,
      allPayment: null,
      isLoadingPayment: true,
      PERMISSION: PERMISSION
    }
  },
  async mounted() {
    this.profile = await getUserProfile()
    this.referralCode = await getLocalStorageExtension(BEECOST_EXTENSION_ID, LocalStorageExtensionConstant.PUB)
    if (this.referralCode) {
      this.referralPersonName = LIST_REFERRAL_CODE[this.referralCode.toLowerCase()]
    }
    if (this.profile) {
      this.allPayment = await getListTransaction()
      //this.referralLink = await getMarketReferralLink()
      await this.updateTransactionLocal()
    }
    this.isLoadingPayment = false
    console.log(`Số lượt phân tích còn lại trong ngày: ${this.freeSearchCount}`)
    console.log(`Số lượt phân tích còn lại trong tuần: ${this.freeSearchCountPerWeek}`)
    console.log(`Số lượt download còn lại trong tuần: ${this.remainProductDownloadCountPerWeek}`)
  },
  created() {
    this.$store.commit(`GeneralModule/${MutationGeneral.setActionName}`, ActionNameConstants.USER)
    this.$store.commit(`SidebarModule/${MutationSidebar.menuSelected}`, ActionNameConstants.USER)
  },
  methods: {
    onClickCopy(text, elementId) {
      let result = copyToClipboard(text)
      if (result) {
        document.getElementById(elementId)
          .classList
          .add('show')
        setTimeout(() => {
          document.getElementById(elementId)
            .classList
            .remove('show')
        }, 700)
      }
    },
    async onClickUpgrade() {
      // await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPricing}`, true)
      this.$router.push({name: 'pricing'})
    },
    async onClickViewReferralProgram() {
      this.$router.push({ name: 'referral_program' })
    },
    async onClickShowPricingPlan() {
      this.$router.push({ name: 'pricing' })
    },
    async onClickShowPricingPlanV2() {
      this.$router.push({ name: 'pricing_v2' })
    },
    onClickBtnPromote() {
      this.$router.push({ name: 'referral_program' })
      // this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPromotedNotification}`, true)
    },
    async onClickShowNoti() {
      await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, true)
    },
    async onClickShowPaymentDetail(voucherId, voucherCode) {
      this.$router.push({ name: 'transaction_detail',
        params: {
          voucherId: voucherId,
          voucherCode: voucherCode
        }
      })
    },
    async onSetReferralCode() {
      if (!LIST_REFERRAL_CODE[this.referralCode.toLowerCase()]) {
        this.$notification['error']({
          message: 'Mã giới thiệu không hợp lệ',
          description: 'Mã giới thiệu không đúng, xin vui lòng nhập lại',
        })
        return
      }
      await setLocalStorageExtension(BEECOST_EXTENSION_ID, LocalStorageExtensionConstant.PUB, this.referralCode)
      await trackingEnterReferralCodeAction()
      this.referralPersonName = LIST_REFERRAL_CODE[this.referralCode.toLowerCase()]
    },
    async updateTransactionLocal() {
      await resetListVoucherWaiting()
      let newTransactionLocal = this.allPayment?.filter(payment => {
        return payment.state === TRANSACTION_STATE.WAITING
      })
        .map(payment => payment.voucher_code)
      await addVoucherWaiting(newTransactionLocal)
    }
  },
  filters: {
    formatTime(value) {
      let date = new Date(value)
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    },
    formatState(value) {
      switch (value) {
        case TRANSACTION_STATE.COMPLETE: {
          return 'Thanh toán thành công'
        }
        case TRANSACTION_STATE.ERROR: {
          return 'Thanh toán thất bại'
        }
        case TRANSACTION_STATE.WAITING: {
          return 'Đang xác minh thanh toán'
        }
      }
    }
  },
  computed: {
    showPopupPricing: {
      get() {
        return this.$store.state.GeneralModule.showPopupPricing
      }
    },
    showPopupInstallExtension: {
      get() {
        return this.$store.state.GeneralModule.showPopupInstallExtension
      }
    }
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterUser,
    mixinStoreGetterPermission,
    mixinStoreGetterPayment
  ],
}
</script>

<style lang="scss" scoped>

.info {
  display: flex;
  align-items: flex-start;
  /*padding-left: 40px;*/
  padding-top: 20px;
  padding-bottom: 40px;
}

.info-detail {
  .attribute {
    display: flex;
    align-content: center;
    padding-bottom: 10px;
    font-size: 15px !important;
    padding-top: 10px;
    border-bottom: 1px solid whitesmoke;


    .field {
      display: flex;
      align-items: center;
      width: 150px;
      margin-right: 12px;
    }

    .value {
      font-weight: bold;
    }
  }
}

.table-info {

  align-items: center;
  display: flex;
  flex-direction: column;

  .img-avatar {
    max-width: 200px;
    height: auto;
  }

  .full-name {
    font-size: 22px;
    font-weight: 500;
  }

  .email {
    font-size: 18px;
  }
}

.remain-user-to-active {
  font-size: 18px;
  font-weight: 700;
  color: #ff7227;
}

.payment-table {
  width: 80%;
  max-width: 800px;
  margin: 16px auto;

  .table-header-row {
    border-bottom: 1px gray solid;
  }

  .table-content-row {
    height: 36px;
    padding: 8px 0;
    border-bottom: 1px dashed;
    border-color: rgba(gray, 60%);

    &:hover {
      background-color: #e8e8e8;
    }

    .status {
      text-transform: uppercase;

      &-complete {
        color: #127C49;
      }

      &-error {
        color: #ee0033;
      }

      &-waiting {
        color: $--color-primary;
      }
    }

    .text-action {
      text-align: center;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: $--color-primary;
      }
    }
  }
}

.box-link-ref {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(gray, 0.1);
  border-radius: 4px;

  .text-link {
    margin: 10px;
    user-select: none;
  }

  .btn-action-wrap {
    height: 100%;
    background-color: rgba($--color-primary, 0.9);
    color: white;
    padding: 10px 12px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }
}

.btn-action-wrap {
  margin-left: 12px;
  background-color: rgba(gray, 0.1);
  padding: 4px 6px;
  border-radius: 4px;
}

.mobile-view {

  h1.title {
    font-size: 14px;
    font-weight: 600;

    .border-left-line {
      border-left: 1px solid #ff7227;
      padding-right: 4px;
      font-size: 1rem;
    }
  }

  .info {
    display: flex;
    align-items: flex-start;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .table-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    width: 30%;

    .img-avatar {
      max-width: 50px;
      height: auto;
    }

    .full-name {
      font-size: 12px;
      font-weight: 500;
    }

    .email {
      width: 100%;
      overflow-x: hidden;
      font-size: 10px;
      margin-right: 8px;
    }
  }

  .info-detail {
    width: 70%;

    .attribute {
      display: flex;
      align-content: center;
      font-size: 10px !important;
      padding-bottom: 8px;
      padding-top: 8px;
      border-bottom: 1px solid whitesmoke;

      .field {
        flex-shrink: 0;
        width: 70px;
        margin-right: 8px;
      }

      .value {
        font-weight: bold;
      }
    }
  }

  .payment-table {
    width: 100%;
    margin: 8px auto;

    .table-header-row {
      border-bottom: 1px #E2E2E2 solid;
      font-size: 10px;

      th {
        padding: 4px 0px;
        text-align: center;
        font-weight: 500;
      }
    }

    .table-content-row {
      font-size: 9px;
      height: 36px;
      padding: 8px 0;
      border-bottom: 1px dashed;
      border-color: rgba(gray, 60%);

      &:hover {
        background-color: #e8e8e8;
      }

      .status {
        text-transform: uppercase;

        &-complete {
          color: #127C49;
        }

        &-error {
          color: #ee0033;
        }

        &-waiting {
          color: $--color-primary;
        }
      }

      .text-action {
        text-align: center;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: $--color-primary;
        }
      }
    }
  }

  .tooltip .tooltiptext {
    right: 0;
    top: -100%;
    left: -150%;
    text-align: center;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  color: #ff7227;
  //text-align: center;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -100%;
  padding-left: 10px;
  text-align: left;
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip .tooltiptext.show {
  visibility: visible;
  opacity: 1;
}

.text-highlight {
  color: $--color-primary;
}

.text-note {
  font-size: 0.7rem;
}

.text-action {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $--color-primary;
  }
}
</style>
